:root {
    color-scheme: light dark;
    --text-color: #000;
    --black: #000;
    --footer: #fbf9f9;
    --white: white;
    --background: white;
    --gray: #4c4c4c;
    --semi-white: #eee;
    --blue: #1277EB;
    // Fonts
    --font-sans: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    --font-mono: 'SFMono-Light', 'SF Mono', 'Ubuntu Mono', Consolas, 'DejaVu Sans Mono', Menlo, monospace;
}

@media (prefers-color-scheme: dark) {
    :root {
        --text-color: #fff;
        --black: #fff;
        --footer: #000;
        --white: #000;
        --background: #1e1e1e;
        --gray: #dbdbdb;
        --semi-white: #333;
    }

    header.landing .logo {
        background: url("../img/logo-white.svg") !important;
        background-size: contain !important;
        background-repeat: no-repeat !important;
    }
}

@media (prefers-color-scheme: dark) and (max-width: 960px) {
    article {
        .text {
            background: rgba(0, 0, 0, 0.7) !important;
        }
    }
}

body {
    font-family: var(--font-sans);
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: var(--text-color);
    background: var(--background);
}

html {
    overflow-x: hidden
}

.langs {
    position: absolute;
    top: 5px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        margin: 5px;
    }
}

header.landing {
    margin: 0;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .text {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;

        .logo {
            background: url("../img/logo.svg");
            background-size: contain;
            background-repeat: no-repeat;
            width: 960px;
            height: 125px;
        }

        h1 {
            font-family: var(--font-sans);
            font-style: normal;
            font-weight: bold;
            font-size: 64px;
            line-height: 76px;
            // text-align: justify;
            max-width: 900px;
        }

        .description {
            max-width: 900px;
            font-family: var(--font-sans);
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            text-align: justify;
        }

        .links {
            margin-top: 15px;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;

            a {
                padding-right: 15px;
                font-family: var(--font-sans);
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
                color: var(--blue);
                text-decoration: none;
            }
        }
    }

    .illustration {
        width: 500px;
        height: 500px;
        background: url("../img/hero.svg");
        background-size: contain;
        background-repeat: no-repeat;
    }
}

article {
    height: auto;

    .figure {
        position: relative;
        display: flex;
        flex-direction: column;

        .step {
            width: 57vw;
            height: 100vh;
        }

        #step-1 {
            background-image: url("../img/step1.svg");
            background-size: contain;
            background-repeat: no-repeat;
        }

        #step-2 {
            background-image: url("../img/step2.svg");
            background-size: contain;
            background-repeat: no-repeat;
        }

        #step-2-5 {
            background-image: url("../img/step2-5.svg");
            background-size: contain;
            background-repeat: no-repeat;
        }

        #step-3 {
            background-image: url("../img/step3.svg");
            background-size: contain;
            background-repeat: no-repeat;
        }

        #step-4 {
            background-image: url("../img/step4.svg");
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}

.text-container {
    height: calc(5*100vh);
    position: absolute;
    top: 100vh;
    left: 60vw;
    z-index: auto;

    .text {
        position: -webkit-sticky;
        position: sticky;
        top: 100px;
        width: 450px;
        max-width: calc(40vw - 20px);
        text-align: justify;

        h1 {
            font-family: var(--font-sans);
            font-style: normal;
            font-weight: bold;
            font-size: 64px;
            line-height: 76px;
        }

        .bold {
            font-family: var(--font-sans);
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
        }

        p {
            font-family: var(--font-sans);
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;

            code {
                font-family: var(--font-mono);
            }
        }
    }
}

.animation {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        font-family: var(--font-sans);
        font-style: normal;
        font-weight: bold;
        font-size: 64px;
        line-height: 76px;
        text-align: center;
    }

    .bold {
        max-width: 460px;
        font-family: var(--font-sans);
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
    }

    .walkthrough {
        background: var(--blue);
        color: white;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        padding: .5rem 1.5rem;
        border: 0;
        border-radius: 3px;
        margin: 10px;
        cursor: pointer;
    }

    .app {
        margin-top: 10px;

        canvas {
            width: 400px;
            height: 400px;
        }

        .selector {
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            width: 396px;
            padding: 0 2px;
            height: 32px;
            background: #c4c4c4;
            border-radius: 5px;
            color: black;

            div {
                width: 120px;
                height: 28px;
                text-align: center;
                line-height: 28px;
                transition: 100ms ease-in-out;
            }

            .active {
                background: #ffffff;
                border-radius: 5px;
            }
        }

        .panel {
            height: 400px;

            .selector {
                margin-top: 0px;
            }

            .row {
                width: 396px;
                padding: 0 2px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 13px;

                .value {
                    text-align: right;
                    color: var(--gray);

                    &.show {
                        color: #007aff;
                        cursor: pointer;
                    }
                }
            }
        }

        .control {
            height: 400px;

            .row {
                width: 396px;
                padding: 0 2px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding-top: 13px;

                .value {
                    text-align: right;
                    color: var(--gray);

                    &.show {
                        color: #007aff;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    footer {
        height: 300px;
        text-align: center;
        font-family: var(--font-sans);
        font-style: normal;
        font-size: 24px;
        line-height: 29px;
        text-align: center;

        a {
            color: inherit;
        }

        small {
            opacity: .4;
            &.legal {
                opacity: 1;
                font-size: 12px;
                line-height: 16px;
                color: var(--blue);
                a {
                    margin: 5px;
                }
            }
        }
    }

    .scientist {
        position: absolute;
        bottom: calc(-6*100vh);
        left: 0;
        background: url("../img/scientist.svg");
        background-size: contain;
        background-position: bottom left;
        background-repeat: no-repeat;
        height: 75vh;
        width: 50vw;
        z-index: -10;
    }

    .sitting {
        position: absolute;
        bottom: calc(-6*100vh);
        right: 0;
        background: url("../img/sitting.svg");
        background-size: contain;
        background-position: bottom right;
        background-repeat: no-repeat;
        height: 60vh;
        width: 50vw;
        z-index: -10;
    }
}

@media (max-width: 1460px) {
    article {
        margin-top: 500px;
    }

    .text-container {
        top: calc(100vh + 500px);
    }

    .animation {

        .scientist,
        .sitting {
            bottom: calc(-6*100vh - 500px);
        }
    }
}

@media (max-width: 960px) {
    header.landing {
        .text {
            .logo {
                max-width: 500px !important;
                width: 90vw;
                height: 75px;
            }

            h1 {
                font-size: 33px !important;
                line-height: 36px !important;
                margin: 0 !important;
                margin-bottom: 10px !important;
                max-width: 500px !important;
                width: 90vw;
                overflow-wrap: break-word;
                hyphens: auto;
                -webkit-hyphens: auto;
            }

            .description {
                font-size: 12px !important;
                line-height: 16px !important;
                max-width: 500px !important;
                width: 90vw;
                text-align: justify !important;
            }

            .links {
                max-width: 500px !important;
                width: 90vw;
                a {
                    font-size: 11px !important;
                    line-height: 13px !important;
                }

            }
        }
    }

    article {
        padding-bottom: 50vh;

        .figure {
            .step {
                width: 90vw;
            }
        }

        .text-container {
            position: absolute;
            top: calc(100vh + 500px);
            left: 0;
            height: calc(5*100vh + 50vh);

            .text {
                position: -webkit-sticky;
                position: sticky;
                top: 40vh;
                margin-left: 15px;
                width: calc(100vw - 60px);
                height: calc(60vh - 40px);
                max-width: 100vw;
                background: rgba(232, 232, 232, 0.7);
                -webkit-backdrop-filter: saturate(180%) blur(10px);
                backdrop-filter: saturate(180%) blur(10px);

                border-radius: 25px;
                padding: 15px;

                h1 {
                    line-height: unset;
                    font-size: 36px;
                }

                .bold {
                    font-size: 18px;
                    line-height: 21px;
                }

                p {
                    font-size: 14px;
                    line-height: 17px;
                }
            }
        }
    }

    .animation {
        justify-content: start !important;

        h1 {
            font-size: 44px;
            line-height: 56px;
        }

        .scientist,
        .sitting {
            bottom: calc(-6*100vh - 500px - 50vh - 500px);
        }

        .bold {
            width: 100vw !important;
            max-width: 350px !important;
            font-size: 16px !important;
            line-height: 21px !important;
        }

        .app {
            canvas {
                width: 300px;
                height: 300px;
            }

            .selector {
                width: 296px;
            }

            .panel {
                height: 300px;

                .row {
                    width: 296px;
                }
            }

            .control {
                height: 300px;

                .row {
                    width: 296px;
                }
            }
        }
    }
}

.popup {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: none;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: saturate(180%) blur(10px);
    backdrop-filter: saturate(180%) blur(10px);
    color: black;

    .infobox {
        width: 100vw;
        height: 100vh;
        max-width: 450px;
        max-height: 600px;
        background: white;
        border-radius: 15px;

        .cross {
            background: url("../img/cross.svg");
            background-size: cover;
            position: relative;
            top: 22px;
            left: 15px;
            width: 25px;
            height: 25px;
            z-index: 10;
            cursor: pointer;
        }

        .title {
            font-family: var(--font-sans);
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 44px;
            text-align: center;
            position: relative;
            top: -15px;
        }

        .container {
            overflow-y: scroll;
            margin-left: 10px;
            width: calc(100vw - 20px);
            max-width: calc(450px - 20px);
            height: calc(100vh - 69px);
            max-height: calc(600px - 69px);

            .center {
                font-family: var(--font-sans);
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 36px;
                color: var(--gray);
                text-align: center;
            }

            .row {
                padding: 0 2px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding-top: 13px;

                .value {
                    margin-left: 20px;
                    text-align: right;
                    color: #4c4c4c;
                }
            }
        }
    }
}